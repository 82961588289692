import(/* webpackMode: "eager" */ "/home/runner/work/sabika-landing-page/sabika-landing-page/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sabika-landing-page/sabika-landing-page/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-ibm\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"ibm\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sabika-landing-page/sabika-landing-page/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"IBM_Plex_Sans_Arabic\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-ibm-arabic\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"ibmArabic\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sabika-landing-page/sabika-landing-page/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Tajawal\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-tajawal\",\"weight\":[\"200\",\"300\",\"400\",\"500\",\"700\",\"800\",\"900\"]}],\"variableName\":\"tajawal\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/runner/work/sabika-landing-page/sabika-landing-page/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sabika-landing-page/sabika-landing-page/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/sabika-landing-page/sabika-landing-page/src/app/QueryProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/sabika-landing-page/sabika-landing-page/src/components/ClientOnly/ClientOnly.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/sabika-landing-page/sabika-landing-page/src/components/home/components/GoogleAnalytics.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/sabika-landing-page/sabika-landing-page/src/components/home/components/GoogleAnalyticsDev.jsx");
