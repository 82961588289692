"use client";
import Modal from "@mui/material/Modal";
import Image from "next/image";
import { useModal } from "src/zustand/useModal";

export default function GeneralModal() {
  const { openModal, setOpenModal, header, text, buttonText, image, onClick } =
    useModal();
  return (
    <div style={{ display: "none" }}>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="w-full max-w-[90%] py-12 bg-white  rounded-2xl grid text-center px-6  border-white"
          style={{
            boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012`,
          }}
        >
          {image && (
            <Image
              src={image}
              alt="modal-image"
              className="mx-auto mb-4"
              width={100}
              height={100}
            />
          )}
          {header && (
            <p className="text-[#404040] font-ibmArabic font-medium text-xl w-full">
              {header}
            </p>
          )}
          {text && (
            <p className="text-[#595959] text-sm font-ibm text-center mb-8 mt-1 w-full">
              {text}
            </p>
          )}
          <button
            onClick={() => {
              if (onClick) {
                onClick();
              }
              setOpenModal(false);
            }}
            style={{
              boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012`,
            }}
            className="py-3 w-full bg-mainYellow rounded-xl font-ibmArabic font-medium text-white"
          >
            {buttonText ? buttonText : "Try Again"}
          </button>
        </div>
      </Modal>
    </div>
  );
}
