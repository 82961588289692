"use client";
import { create } from "zustand";
import clock from "@/assets/clock.svg";
import danger from "@/assets/danger.svg";
import circleClose from "@/assets/circleClose.svg";

export const useModal = create((set, get) => ({
  openModalWeb: false,
  setOpenModalWeb: (open) => set({ openModalWeb: open }),
  onClickWeb: () => set({ openModalWeb: false }),
  openModal: false,
  type: "",
  header: "",
  text: "",
  buttonText: "Try Again",
  image: null,
  onClick: () => set({ openModal: false }),
  setOpenModal: (open) => set({ openModal: open }),
  setModal: ({ type, header, text, buttonText, onClick }) => {
    let image = null;
    // Determine the image based on type
    switch (type) {
      case "warning":
        image = danger;
        break;
      case "error":
        image = circleClose;
        break;
      case "waiting":
        image = clock;
        break;
      default:
        image = null; // Fallback if no type matches
    }

    set({ type, header, text, buttonText, image, onClick });
  },
}));
